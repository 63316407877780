import React from "react";
const Fragment = React.Fragment;

function Home() {
    return (
        <Fragment>
        <div className="banner">
            <picture>
                <source srcSet={"images/Welcome.jpg"} media={"(min-width: 1024px)"} />
                <source srcSet={"images/Welcome-mobile.jpg"} media={"(max-width: 640px)"} />
                <img src="images/Welcome.jpg" className="banner-image" alt="Welcome" />
            </picture>
        </div>
        <div className="grid grid-12">
            <h1>Welcome to Perison Global Inc.</h1>
            <p>We are an international freight forwarding company having presence in Ontario and British
                Columbia. We offer transportation and freight forwarding services to our customers across the
                world. With a vast network of agents across the globe, we ensure that our customers get the
                best service at the most reasonable prices.</p>
        </div>
        </Fragment>
    );
}

export default Home;