import React from "react";
import firebase from "firebase";
import '../assets/styles/Form.scss';
import config from "../config/firebase";

require("firebase/firestore");

firebase.initializeApp({
    apiKey: config.firebaseConfig.apiKey,
    authDomain: config.firebaseConfig.authDomain,
    projectId: config.firebaseConfig.projectId
});

var db = firebase.firestore();


class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name:'',
            email:'',
            cname:'',
            pnum:'',
            comments:'',
            hp:''
        }

        this.nameChange     = this.nameChange.bind(this);
        this.emailChange    = this.emailChange.bind(this);
        this.cnameChange    = this.cnameChange.bind(this);
        this.pnumChange     = this.pnumChange.bind(this);
        this.commentsChange = this.commentsChange.bind(this);
        this.handleSubmit   = this.handleSubmit.bind(this);
        this.hpChange       = this.hpChange.bind(this);
    }

    nameChange(e) {
        this.setState({ name: e.target.value })
    }

    emailChange(e) {
        this.setState({ email: e.target.value })
    }

    cnameChange(e) {
        this.setState({ cname: e.target.value })
    }

    pnumChange(e) {
        this.setState({ pnum: e.target.value })
    }

    commentsChange(e) {
        this.setState({ comments: e.target.value })
    }

    hpChange(e) {
        this.setState({ hp: e.target.value })
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.hp === '') {
            const db = firebase.firestore();

            db.collection("contacts").add({
                    name: this.state.name,
                    email: this.state.email,
                    pnum: this.state.pnum,
                    cname: this.state.cname,
                    comments: this.state.comments
                }
            ).then(function (docRef) {
                document.querySelector('.contact-form .success').classList.add('show');
                setTimeout(function () {
                    window.location.href = "/"
                }, 4000);
                console.log("Document written with ID: ", docRef.id);
            })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                });
        } else {
            document.querySelector('.contact-form .success').classList.add('show');
            setTimeout(function () {
                window.location.href = "/"
            }, 4000);
        }
    }



    render() {
        return (
            <form className={"contact-form"} onSubmit={this.handleSubmit} >
                <label><span>Full Name:</span>
                    <input className="input" name="name" id="name" type="text" required placeholder={"Full Name"} onChange={this.nameChange} value={this.state.name}/>
                </label>

                <label><span>Email:</span>
                <input className="input" name="email" id="email" type="email" required placeholder={"Email"} onChange={this.emailChange} value={this.state.email}/>
                </label>

                <label><span>Company Name:</span>
                    <input className="input" name="cname" id="cname" type="text" required placeholder={"Company Name"} onChange={this.cnameChange} value={this.state.cname}/>
                </label>

                <label><span>Phone Number:</span>
                    <input className="input" name="pnum" id="pnum" type="tel" required placeholder={"Phone Number"} onChange={this.pnumChange} value={this.state.pnum}/>
                </label>
                <label className={"hp"}>
                    <input className={"input"} tabIndex={"-1"} name={"hp"} onChange={this.hpChange} value={this.state.hp} type={"string"} />
                </label>

                <label><span>Comments:</span>
                <textarea className="textarea" name="comments" onChange={this.commentsChange} value={this.state.comments} id={"comments"} placeholder={"Comments"}/>
                </label>

                <input className="submit" type="submit" value={"Inquire"} />
                <div className={"success"}>
                    Thanks for you Inquiry, someone will get back to you shortly.
                </div>
            </form>
        )
    }
}

export default Form;