import React from "react";
import Home from "../Pages/Home";
import Services from "../Pages/Services";
import Contact from "../Pages/Contact";


function Content() {
    return (
        <div className="full-width content-cont">
            <Home />
            <Services />
            <Contact />
        </div>
    );
}

export default Content;