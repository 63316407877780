import React from "react";
import Form from "../components/Form";

const Fragment = React.Fragment;


function Contact() {
    return (
        <Fragment>
            <div className="banner">
                <picture>
                    <source srcSet={"images/Contactus.jpg"} media={"(min-width: 1024px)"} />
                    <source srcSet={"images/Contactus-mobile.jpg"} media={"(max-width: 640px)"} />
                    <img src="images/Contactus.jpg" className="banner-image" alt="" />
                </picture>
            </div>
            <div id="contact" className="grid grid-12">
                <h1>Connect with Perison Global</h1>
                <p>To request additional information, or to simply leave us feedback, please complete the Contact & Information Request Form.</p>
                <p>Or you can write to us at <a href={"mailto:sales@perisonglobal.com"}>sales@perisonglobal.com</a></p>
                <Form />
            </div>
        </Fragment>
    );
}

export default Contact;