import React from 'react';

function Logo() {
    return (
        <footer className="full-width">
            <div className="grid grid-12 text-center">
                <p>&copy; {(new Date().getFullYear())} Perison Global - All right reserved</p>
            </div>
        </footer>
    );
}

export default Logo;