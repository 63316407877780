module.exports = {
    firebaseConfig : {
            apiKey: "AIzaSyBLq4ifb4OcLnFNbZV5PdXND8ZKusDhDeU",
            authDomain: "perisonglobal.firebaseapp.com",
            databaseURL: "https://perisonglobal.firebaseio.com",
            projectId: "perisonglobal",
            storageBucket: "perisonglobal.appspot.com",
            messagingSenderId: "506538824723",
            appId: "1:506538824723:web:3f0b3553c41f98225cdbe6",
            measurementId: "G-Q189HQ1CXT"
        }
};