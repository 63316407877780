import React from "react";

function Pods(props) {
    var content = '';
    var title   = props.types.title;
    var text    = props.types.text;
    var image   = props.types.image;

    content = <div className={"pod"}><img src={image} alt={""} /><p className={"pod-title"}>{title}</p><p dangerouslySetInnerHTML={{__html:text}}></p></div>;

    return content;
}

export default Pods;