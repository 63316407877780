import React from "react";
import Pods from "../components/common/Pods";
import '../assets/styles/Pod.scss';
import ServicesData from "../assets/json/services";

const Fragment = React.Fragment;

function getData(dataFeed) {
    var data = [];
    Object.keys(dataFeed).forEach(function (key, index) {

        data.push(<Pods key={key}
                         types={{
                             image: dataFeed[key].image,
                             title: dataFeed[key].title,
                             text: dataFeed[key].text,
                             link: dataFeed[key].link
                         }}/>);
    });

    return data;
}

function Services() {
    var data1 = getData(ServicesData.freight);
    var data2 = getData(ServicesData.insurance);


    var content = (
        <Fragment>
            <div id="services" className="banner">
                <picture>
                    <source srcSet={"images/Services.jpg"} media={"(min-width: 1024px)"} />
                    <source srcSet={"images/Services-mobile.jpg"} media={"(max-width: 640px)"} />
                    <img src="images/Services.jpg" className="banner-image" alt=""/>
                </picture>
            </div>
            <div className="grid grid-12">
                <h1>Our Services</h1>
                <h2>Freight</h2>
                <div className={"services"}>
                    {data1}
                    {/*<Pods types={{image:"https://loremflickr.com/320/240/office",title:"title",text:"text",link:"link"}}/>*/}
                    {/*<Pods name={"Abhi"}/>*/}
                </div>
                <h2>Documentation And Insurance</h2>
                <div className={"services"}>
                    {data2}
                    {/*<Pods types={{image:"https://loremflickr.com/320/240/office",title:"title",text:"text",link:"link"}}/>*/}
                    {/*<Pods name={"Abhi"}/>*/}
                </div>
            </div>
        </Fragment>
    );

    return content;
}

export default Services;