import React from 'react';
import '../assets/styles/Header.scss';
import logo from "../assets/images/logo.png";
import logoText from "../assets/images/logo-text.png";
import header from "./HeaderNav";
import HeaderNav from "./HeaderNav";
import handleNavClick from "./helpers/Helper"

function Logo() {
    let last_known_scroll_position = 0;
    let ticking = false;

    function doSomething(scroll_pos) {
        let header = document.getElementsByClassName("main-header")[0];

        if (scroll_pos > header.clientHeight+100) {
            header.classList.add("sticky");
        } else {
            header.classList.remove("sticky");
        }
    }

    window.addEventListener('scroll', function(e) {
        last_known_scroll_position = window.scrollY;

        if (!ticking) {
            window.requestAnimationFrame(function() {
                doSomething(last_known_scroll_position);
                ticking = false;
            });

            ticking = true;
        }
    });
    return (
        <header className="main-header">
            {<div className="top-nav">
                <div className="grid grid-12 clearfix">
                    {/*<a className="phone" href="tel:+16479951085">6479951085</a>*/}
                    <a className="email" href="mailto:sales@perisonglobal.com">sales@perisonglobal.com</a>
                    <button className="quote cta-button" onClick={e=>handleNavClick(e,"contact")}>Request a Quote</button>
                </div>
            </div>}
            <div className="grid grid-12 header">
                <a className="site-logo" href="/" target="_self" rel="noopener noreferrer">
                    <img src={logo} className="site-logo" alt="logo" />
                    <img src={logoText} className="site-logo logo-text" alt="logo" />
                    {/*<span>Perison Global</span>*/}
                </a>
                <HeaderNav/>
            </div>
        </header>
    );
}

export default Logo;