import React from "react";
import handleNavClick from "./helpers/Helper"

function HeaderNav() {
    return (
            <nav>
                <ul>
                    <li>
                        <a onClick={handleNavClick} href="/#root" >Home</a>
                    </li>
                    <li>
                        <a onClick={handleNavClick} href="/#services" >Services</a>
                    </li>
                    <li>
                        <a onClick={handleNavClick} href="/#contact" >Contact</a>
                    </li>
                </ul>
            </nav>
    );
}

export default HeaderNav;